import "../callNow/Callnow.css"




function Callnow() {
  return (
    <div className="phone-number">
        <>Call Now : 916-527-4347</>

    </div>
  )
}
export default Callnow